import React from "react";
import { Card } from "react-bootstrap";
import "./home-page.css";
function BlogComponents({ image }) {
  return (
    <div className="blog-section">
      <Card>
        <Card.Img variant="top" src={image} className="card-img" />
      </Card>
      {/* <div className="blog-review">
        <div className="blog-date">
          <span>27</span>
          <span>sep</span>
        </div>
        <div className="blog-info">
          <div className="blog-writerAndcomment">
            <span>
              <i class="fa-solid fa-user me-2"></i>
              Jessica Temphers
            </span>
            <span>
              <i class="fa-solid fa-comment me-2"></i>
              12
            </span>
          </div>
          <span className="blog-title">Here's what you should know before</span>
        </div>
      </div> */}
    </div>
  );
}

export default BlogComponents;

import React from "react";
import styles from "../../styles/FaqPageStyle.module.css";
import { Card, CardBody, Collapse } from "reactstrap";
import { useState } from "react";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import Question from "../../assets/imgs/EF Branding Website/334731_quora_icon.png";
import Answer from "../../assets/imgs/EF Branding Website/letter-a (1).png";
import { useTranslation } from "react-i18next";
const QuesFour = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center">
        <img src={Question} alt="" style={{ width: "30px", height: "30px" }} />
        <button id="toggler4" className={styles.btn} onClick={toggle}>
          <span style={{ textAlign: "left" }}> {t("faq.q_four")}</span>
          {!isOpen ? (
            <div className={styles.icon_container}>
              <AiOutlinePlusCircle className={styles.icon} />
            </div>
          ) : (
            <div className={styles.icon_container}>
              <AiOutlineMinusCircle className={styles.icon} />
            </div>
          )}
        </button>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="d-flex mt-3">
          <img src={Answer} alt="" className={styles.img} />
          <Card style={{ width: "100%" }}>
            <CardBody>
              <ul>
                <li className="d-flex flex-column mb-3">
                  <span>{t("faq.q_four_ans_one_title")}</span>
                  <span className="ps-3">{t("faq.q_four_ans_one")}</span>
                </li>
                <li className="d-flex flex-column mb-3">
                  <span>{t("faq.q_four_ans_two_title")}</span>
                  <span className="ps-3">{t("faq.q_four_ans_two")}</span>
                </li>
                <li className="d-flex flex-column mb-3">
                  <span>{t("faq.q_four_ans_three_title")}</span>
                  <span className="ps-3">{t("faq.q_four_ans_three")}</span>
                </li>
                <li className="d-flex flex-column mb-3">
                  <span>{t("faq.q_four_ans_four_title")}</span>
                  <span className="ps-3">{t("faq.q_four_ans_four")}</span>
                </li>
                <li className="d-flex flex-column mb-3">
                  <span>{t("faq.q_four_ans_five_title")}</span>
                  <span className="ps-3">{t("faq.q_four_ans_five")}</span>
                </li>
                <li className="d-flex flex-column mb-3">
                  <span>{t("faq.q_four_ans_six_title")}</span>
                  <span className="ps-3">{t("faq.q_four_ans_six")}</span>
                </li>
              </ul>
            </CardBody>
          </Card>
        </div>
      </Collapse>
    </div>
  );
};
export default QuesFour;

import React from "react";
import styles from "../styles/ServicePageStyle.module.css";
import { Container, Row, Button } from "react-bootstrap";
import whychooseus from "./../assets/imgs/whychooseus.jpg";
import { FaCheckCircle } from "react-icons/fa";
import { FaBox } from "react-icons/fa";
import Ship from "./../assets/icons/Ship.svg";
import Order from "./../assets/icons/Order.svg";
import Waybill from "./../assets/icons/Waybill.svg";
import ArrowForwardCirlce from "./../assets/imgs/arrow_forward_circle.png";
import "./service-page.css";
import { useTranslation } from "react-i18next";

const ServicePage = () => {
  const { t } = useTranslation();
  return (
    <div>
      <section className={styles.content}>
        <div className={styles.backdrop}></div>
      </section>
      <section>
        <Container fluid>
          <Row className={styles.office_details}>
            <div className="col-10">
              <h4>{t("services.welcome")}</h4>
              <p>{t("services.welcome_detail")}</p>
              <h5>{t("services.specialize")}</h5>
              <ul>
                <li>{t("services.specialize_one")}</li>
                <li>{t("services.specialize_two")}</li>
                <li>{t("services.specialize_three")}</li>
                <li>{t("services.specialize_four")}</li>
                <li>{t("services.specialize_five")}</li>
                <li>{t("services.specialize_six")}</li>
                <li>{t("services.specialize_seven")}</li>
                <li>{t("services.specialize_eight")}</li>
                <li>{t("services.specialize_nine")}</li>
                <li>{t("services.specialize_ten")}</li>
                <li>{t("services.specialize_eleven")}</li>
                <li>{t("services.specialize_twelve")}</li>
              </ul>
              <h5>{t("services.your_goto")}</h5>
              <p>{t("services.your_goto_detail")}</p>
            </div>
          </Row>
        </Container>
      </section>
      {/* <section className={`${styles.content} ${styles.second_banner_image}`}>
        <div className={styles.bannerDrop}>
          <h3>Service Network</h3>
          <div>
            <div className={styles.bannerDropInfo}>
              <img src={ArrowForwardCirlce} />
              <p>North Asia - ASEAN</p>
            </div>
            <div className={styles.bannerDropInfo}>
              <img src={ArrowForwardCirlce} />
              <p>Infra Asia - ASEAN</p>
            </div>
            <div className={styles.bannerDropInfo}>
              <img src={ArrowForwardCirlce} />
              <p>Asia - India Subcontinent</p>
            </div>
            <div className={styles.bannerDropInfo}>
              <img src={ArrowForwardCirlce} />
              <p>Asia - Gulf</p>
            </div>
            <div className={styles.bannerDropInfo}>
              <img src={ArrowForwardCirlce} />
              <p>Feeder</p>
            </div>
          </div>
        </div>
      </section> */}
      <section className="why-choose-us-section">
        <Container fluid>
          <Row>
            <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
              <img src={whychooseus} style={{ width: "90%", height: "80%" }} />
            </div>
            <div className="col-lg-6 col-md-12 d-flex flex-column justify-content-center align-items-center ">
              <div className="why-choose-us-header">
                <h3 className="fw-bold">{t("home.why_choose")}</h3>
              </div>
              <div className="d-flex">
                <div className="check-icon">
                  <FaCheckCircle />
                </div>
                <div className="why-choose-us-content">
                  <h5>{t("home.title_one")}</h5>
                  <p>{t("home.title_one_detail")}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="check-icon">
                  <FaCheckCircle />
                </div>

                <div className="why-choose-us-content">
                  <h5>{t("home.title_two")}</h5>
                  <p>{t("home.title_two_detail")}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="check-icon">
                  <FaCheckCircle />
                </div>
                <div className="why-choose-us-content">
                  <h5>{t("home.title_three")}</h5>
                  <p>{t("home.title_three_detail")}</p>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      <section className={"solution-section"}>
        <div className="header">
          <FaBox className="box-icon" />
          <p>{t("services.logistic")}</p>
        </div>
        <div className="info-container">
          <div className="info">
            <img src={Ship} />
            <div>
              <h6>{t("services.logistic_services")}</h6>
              <p>{t("services.logistic_services_detail")}</p>
            </div>
          </div>
          <div className="info">
            <img src={Order} />
            <div>
              <h6>{t("services.logistic_price")}</h6>
              <p> {t("services.logistic_price_detail")}</p>
            </div>
          </div>
          <div className="info">
            <img src={Waybill} />
            <div>
              <h6>{t("services.logistic_support")}</h6>
              <p> {t("services.logistic_support_detail")}</p>
            </div>
          </div>
        </div>
      </section>
      <section className={"next-step-section"}>
        <div className="header">
          <h6>{t("services.next_step")}</h6>
        </div>
        <p>{t("services.next_step_detail")}</p>
        <Button
          onClick={() => {
            window.location = "/contact-us";
          }}
        >
          Contact Us Now
        </Button>
      </section>
    </div>
  );
};
export default ServicePage;

import React from "react";
import { useState } from "react";
import { Container, Row } from "reactstrap";
import QuesOne from "./ques1";
import QuesTwo from "./ques2";
import QuesThree from "./ques3";
import QuesFour from "./ques4";
import QuesFive from "./ques5";
import { useTranslation } from "react-i18next";

const FaqPage = () => {
  const { t } = useTranslation();
  return (
    <div className="py-5">
      {/* <section className={styles.content}>
        <div className={styles.backdrop}></div>
      </section> */}
      <section>
        <Container className="px-md-5 px-sm-1">
          <Row>
            <div className="d-flex justify-content-center align-items-center">
              <h4>{t("faq.faq")}</h4>
            </div>
          </Row>
          <Row className="mt-5">
            <QuesOne />
          </Row>
          <Row className="mt-3">
            <QuesTwo />
          </Row>
          <Row className="mt-3">
            <QuesThree />
          </Row>
          <Row className="mt-3">
            <QuesFour />
          </Row>
          <Row className="mt-3">
            <QuesFive />
          </Row>
        </Container>
      </section>
    </div>
  );
};
export default FaqPage;

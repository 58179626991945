import React from "react";
import styles from "../../styles/FaqPageStyle.module.css";
import { Card, CardBody, Collapse } from "reactstrap";
import { useState } from "react";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import Question from "../../assets/imgs/EF Branding Website/334731_quora_icon.png";
import Answer from "../../assets/imgs/EF Branding Website/letter-a (1).png";
import { useTranslation } from "react-i18next";

const QuesTwo = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center">
        <img src={Question} alt="" style={{ width: "30px", height: "30px" }} />
        <button id="toggler2" className={styles.btn} onClick={toggle}>
          <span style={{ textAlign: "left" }}>{t("faq.q_two")}</span>
          {!isOpen ? (
            <div className={styles.icon_container}>
              <AiOutlinePlusCircle className={styles.icon} />
            </div>
          ) : (
            <div className={styles.icon_container}>
              <AiOutlineMinusCircle className={styles.icon} />
            </div>
          )}
        </button>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="d-flex mt-3">
          <img src={Answer} alt="" className={styles.img} />
          <Card style={{ width: "100%" }}>
            <CardBody>
              <span>{t("faq.q_two_ans")}</span>
              <ul className="mt-4">
                <li>{t("faq.q_two_ans_one")}</li>
                <li>{t("faq.q_two_ans_two")}</li>
                <li>{t("faq.q_two_ans_three")}</li>
                <li> {t("faq.q_two_ans_four")} </li>
                <li> {t("faq.q_two_ans_five")}</li>
                <li>{t("faq.q_two_ans_six")}</li>
                <li>{t("faq.q_two_ans_seven")}</li>
                <li> {t("faq.q_two_ans_eight")}</li>
                <li> {t("faq.q_two_ans_nine")}</li>
                <li> {t("faq.q_two_ans_ten")}</li>
                <li> {t("faq.q_two_ans_eleven")}</li>
                <li> {t("faq.q_two_ans_twelve")}</li>
              </ul>
            </CardBody>
          </Card>
        </div>
      </Collapse>
    </div>
  );
};
export default QuesTwo;

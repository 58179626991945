import {
  Col,
  Container,
  Row,
  Button,
  Card,
  InputGroup,
  Form,
  Carousel,
} from "react-bootstrap";
import React, { useState } from "react";
// import Carousel from "nuka-carousel";
import {
  BsCursor,
  BsMailbox,
  BsPhone,
  BsFacebook,
  BsTwitter,
  BsLinkedin,
} from "react-icons/bs";
import { MdSupportAgent } from "react-icons/md";
import { GoLocation } from "react-icons/go";
import HomePageBanner from "./../assets/imgs/home_page_banner.jpg";
import { FaCheckCircle } from "react-icons/fa";
import GetQuote from "../components/NavBar/GetQuote";
import ThankMsg from "../components/NavBar/ThankMsg";

import DeliCar from "./../assets/imgs/deli_car.png";
import * as styles from "../styles/HomePageStyle.module.css";
import BlogComponents from "./BlogComponents";
import TalkingGroup from "./TalkingGroup";

import WarehouseImg from "./../assets/imgs/warehousing.jpg";
import OceanImg from "./../assets/imgs/ocean.jpg";
import D2DImg from "./../assets/imgs/d2d.jpg";

import Image1 from "./../assets/imgs/1.jpg";
import Image2 from "./../assets/imgs/2.jpg";
import Image3 from "./../assets/imgs/3.jpg";
import Image4 from "./../assets/imgs/4.jpg";
import Image5 from "./../assets/imgs/5.jpg";
import Image6 from "./../assets/imgs/6.jpg";
import Image7 from "./../assets/imgs/7.jpg";
import Image8 from "./../assets/imgs/8.jpg";
import Image9 from "./../assets/imgs/9.jpg";
import whychooseus from "./../assets/imgs/whychooseus.jpg";
import warehouseImg1 from "./../assets/imgs/Warehouse Photo/1.jpg";
import warehouseImg2 from "./../assets/imgs/Warehouse Photo/2.jpg";
import warehouseImg3 from "./../assets/imgs/Warehouse Photo/3.jpg";
import warehouseImg4 from "./../assets/imgs/Warehouse Photo/4.jpg";
import warehouseImg5 from "./../assets/imgs/Warehouse Photo/5.jpg";
import warehouseImg6 from "./../assets/imgs/Warehouse Photo/6.jpg";
import warehouseImg7 from "./../assets/imgs/Warehouse Photo/7.jpg";
import warehouseImg8 from "./../assets/imgs/Warehouse Photo/8.jpg";
import warehouseImg9 from "./../assets/imgs/Warehouse Photo/9.jpg";
import warehouseImg10 from "./../assets/imgs/Warehouse Photo/10.jpg";
import warehouseImg11 from "./../assets/imgs/Warehouse Photo/11.jpg";
import warehouseImg12 from "./../assets/imgs/Warehouse Photo/12.jpg";
import warehouseImg13 from "./../assets/imgs/Warehouse Photo/13.jpg";
import warehouseImg14 from "./../assets/imgs/Warehouse Photo/14.jpg";
import warehouseImg15 from "./../assets/imgs/Warehouse Photo/15.jpg";
import "./home-page.css";
import ReviewSlider from "./ReviewSlider";
import { useTranslation } from "react-i18next";

const HomePage = () => {
  const [search, setSearch] = React.useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isMsgOpen, setIsMsgOpen] = useState(false);
  const { t } = useTranslation();

  const toggle = () => setIsOpen(!isOpen);
  const msgToggle = () => {
    setIsMsgOpen(!isMsgOpen);
  };
  return (
    <div>
      <section className={styles.content}>
        <div className={styles.backdrop}>
          <Container className="carousel-inner-content">
            <Row>
              <Col sm={12} md={7} className="my-3">
                <h1
                  className="text-white"
                  style={{ textShadow: "1px 1px 1px black" }}
                >
                  {t("home.safe_reliable")}
                  <span style={{ textShadow: "1px 1px 1px white" }}>
                    {t("home.logistic")}
                  </span>{" "}
                  {t("home.solution")}!
                </h1>
                <div className={styles.tb_container}>
                  <input
                    type="text"
                    placeholder="WayBill Number"
                    className="form-control"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Button
                    onClick={() => {
                      if (search.length > 0)
                        window.location = "/tracking?waybillnumber=" + search;
                    }}
                  >
                    Truck & Trace
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      {/* side banner */}
      <section className="side-banner">
        <div className="call-support">
          <MdSupportAgent className="support-icon" />
          <div>
            <h5>{t("home.call_support")}</h5>
            <p>24/7 Support Line : +{t("home.phone")}</p>
          </div>
        </div>
        <div className="location">
          <GoLocation className="location-icon" />
          <div>
            <h5>{t("home.our_location")}</h5>
            <p>{t("home.location_details")}</p>
          </div>
        </div>
      </section>
      {/* side banner */}
      {/* services */}
      <section className="services">
        <div className="subtitle">{t("home.our_service")}</div>
        <h2 className="title">{t("home.service_type")}</h2>
        <div className="image-cards">
          <div>
            <img src={OceanImg} />
            <h3>{t("home.ocean_fright")}</h3>
            <p>{t("home.ocean_fright_detail")}</p>
          </div>
          <div>
            <img src={WarehouseImg} />
            <h3>{t("home.warehouse")}</h3>
            <p>{t("home.warehouse_detail")}</p>
          </div>
          <div>
            <img src={D2DImg} />
            <h3>{t("home.doortodoor")}</h3>
            <p>{t("home.doortodoor_detail")}</p>
          </div>
        </div>
        <div className="text-container">
          <p style={{ fontWeight: "bold" }} md={6} sm={12}>
            Our list of services does not end here. We’ll adapt to your
            particular needs.
          </p>
          <p
            style={{ fontWeight: "bold", cursor: "pointer" }}
            onClick={toggle}
            md={6}
            sm={12}
          >
            Get a Quote for Your Business
          </p>
        </div>
      </section>
      {/* services */}
      {/* video */}
      {/* <section className="video-container">
        <div>
          <iframe
            src="https://www.youtube.com/embed/0tMsqhmenbs"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div>
          <h1>Logistics Service System</h1>
          <p>
           Eflogistics Group Forwarding Logistics has developed the software for the
            whole logistics service as own and it is thus a more systematic
            service that including of shipping date and time, factory ‘s
            location, buyer’s product, Location of arrival, quantities and so
            on.
          </p>
        </div>
        <img className="deli-car" src={DeliCar} />
      </section> */}
      {/* video */}

      {/*About Our Company*/}
      <section
        className="about"
        style={{ margin: 0, padding: 0, marginTop: 30 }}
      >
        <Container>
          <Row>
            <div className="about-title col-lg-6 col-md-12">
              <span>{t("home.about_company")}</span>
              <h2>{t("home.company_type")}</h2>
            </div>
            <Container className="d-flex flex-column-reverse flex-lg-row ">
              <div className="col-lg-6 col-md-12 col-sm-12 mb-5 mt-5">
                <div>
                  <p>{t("home.company_detail_one")}</p>
                  <p>{t("home.company_detail_two")}</p>
                </div>
                <Button
                  onClick={() => {
                    window.location = "/about-us";
                  }}
                >
                  More about us
                </Button>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 justify-content-center">
                <div className="about-img">
                  <img src={HomePageBanner} />
                </div>
              </div>
            </Container>
          </Row>
        </Container>
      </section>

      <section className="why-choose-us-section">
        <Container fluid>
          <Row>
            <div className="col-lg-6 col-md-12 d-flex justify-content-center align-items-center">
              <img src={whychooseus} style={{ width: "90%", height: "80%" }} />
            </div>
            <div className="col-lg-6 col-md-12 d-flex flex-column justify-content-center align-items-center ">
              <div className="why-choose-us-header">
                <h3 className="fw-bold">{t("home.why_choose")}</h3>
              </div>
              <div className="d-flex">
                <div className="check-icon">
                  <FaCheckCircle />
                </div>
                <div className="why-choose-us-content">
                  <h5>{t("home.title_one")}</h5>
                  <p>{t("home.title_one_detail")}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="check-icon">
                  <FaCheckCircle />
                </div>

                <div className="why-choose-us-content">
                  <h5>{t("home.title_two")}</h5>
                  <p>{t("home.title_two_detail")}</p>
                </div>
              </div>
              <div className="d-flex">
                <div className="check-icon">
                  <FaCheckCircle />
                </div>
                <div className="why-choose-us-content">
                  <h5>{t("home.title_three")}</h5>
                  <p>{t("home.title_three_detail")}</p>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      {/* why choose us */}

      {/* blog */}
      <section className="blog-section-container">
        <h3 className="m-0">Blogs</h3>
        <Carousel className="" variant="dark">
          <Carousel.Item>
            <div className="blog-group">
              <BlogComponents image={warehouseImg1} />
              <BlogComponents image={warehouseImg2} />
              <BlogComponents image={warehouseImg3} />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="blog-group">
              <BlogComponents image={warehouseImg4} />
              <BlogComponents image={warehouseImg5} />
              <BlogComponents image={warehouseImg6} />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="blog-group">
              <BlogComponents image={warehouseImg7} />
              <BlogComponents image={warehouseImg8} />
              <BlogComponents image={warehouseImg9} />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="blog-group">
              <BlogComponents image={warehouseImg10} />
              <BlogComponents image={warehouseImg11} />
              <BlogComponents image={warehouseImg12} />
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="blog-group">
              <BlogComponents image={warehouseImg13} />
              <BlogComponents image={warehouseImg14} />
              <BlogComponents image={warehouseImg15} />
            </div>
          </Carousel.Item>
        </Carousel>
      </section>

      {/* blog */}

      {/* <section className="blog-section-container-mobile">
        <BlogComponents
          images={[
            Image1,
            Image2,
            Image3,
            Image4,
            Image5,
            Image6,
            Image7,
            Image8,
            Image9,
          ]}
        />
      </section> */}

      {/* ACCOMPLISHMENTS */}
      {/* <section className="accomplish">
        <div className="subtitle">ACCOMPLISHMENTS</div>
        <h1 className="title">
          Let the numbers speak for
          <br />
          themselves
        </h1>
        <div className="numbers-container">
          <div>
            <img src="https://moovit.foxthemes.me/wp-content/uploads/2019/08/10a.png" />
            <h1>5,7343</h1>
            <h2>Delivered packages</h2>
            <p>
              We strongly support best practice sharing across our operations
              around the world and across various industrial sectors.
            </p>
          </div>
          <div>
            <img src="https://moovit.foxthemes.me/wp-content/uploads/2019/08/11a.png" />
            <h1>123</h1>
            <h2>Countries covered</h2>
            <p>
              We strongly support best practice sharing across our operations
              around the world and across various industrial sectors.
            </p>
          </div>
          <div>
            <img src="https://moovit.foxthemes.me/wp-content/uploads/2019/08/12.png" />
            <h1>476</h1>
            <h2>Tons of goods</h2>
            <p>
              We strongly support best practice sharing across our operations
              around the world and across various industrial sectors.
            </p>
          </div>
        </div>
      </section> */}
      <GetQuote isOpen={isOpen} toggle={toggle} msgToggle={msgToggle} />
      <ThankMsg msgToggle={msgToggle} isMsgOpen={isMsgOpen} />
    </div>
  );
};

export default HomePage;

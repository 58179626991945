import React, { useMemo } from "react";
import { Container, Form, Row, Button, Col, Tabs, Tab } from "react-bootstrap";
import styles from "./track-page.module.css";
import "../App.css";
import "./track-page.css";
import { RiShip2Fill } from "react-icons/ri";
import { useLocation, useSearchParams } from "react-router-dom";
import { URL, URL1,URL2 } from "../commons/constants";
import moment from "moment";
import { Step, Stepper } from "react-form-stepper";
import { ReactComponent as OrderSvg } from "./../assets/icons/Order.svg";
import { ReactComponent as ShipSvg } from "./../assets/icons/Ship.svg";
import { ReactComponent as SignSvg } from "./../assets/icons/Sign.svg";
import { ReactComponent as WarehouseSvg } from "./../assets/icons/Warehouse.svg";
import { ReactComponent as WaybillSvg } from "./../assets/icons/Waybill.svg";
import { useTranslation } from "react-i18next";

const TrackingPage = () => {
  const [search, setSearch] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [notFound, setNotFound] = React.useState(false);
  const [data, setData] = React.useState(null);
  const location = useLocation().search;
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();

  const onSearchData = async () => {
    window.location = "/tracking?waybillnumber=" + search;
  };

  const onSearch = async (waybillnumber) => {
    let searchValue = search || waybillnumber;
    searchValue = searchValue.toUpperCase();

    if (searchValue.length > 0) {
      try {
        setLoading(true);
        let response = await fetch(
          `${URL}/frontend/get-waybill-tracking?waybill_number=${searchValue}`
        );
        let data = await response.json();

        let response1 = await fetch(
          `${URL1}/frontend/get-waybill-tracking?waybill_number=${searchValue}`
        );
        let data1 = await response1.json();

        let response2 = await fetch(
          `${URL2}/frontend/get-waybill-tracking?waybill_number=${searchValue}`
        );
        let data2 = await response2.json();

        

        if (
          data === "Waybill number not found" &&
          data1 === "Waybill number not found" &&
          data2 === "Waybill number not found"
        ) {
          setNotFound(true);
          setLoading(false);
          setData(null);
          return;
        } else {
          // setDisabled(false);
        }

        setData(data === "Waybill number not found" ? data1 === "Waybill number not found" ? data2 : data1 : data);
        setLoading(false);
        setNotFound(false);
      } catch {
        setData(null);
        setLoading(false);
        setNotFound(true);
      }
    }
  };

  React.useEffect(() => {
    const waybillnumber = new URLSearchParams(location).get("waybillnumber");
    if (waybillnumber) {
      setSearch(waybillnumber);
      onSearch(waybillnumber);
    }
  }, [location]);

  React.useEffect(() => {
    async function fetchData() {
      if (searchParams.get("waybill_number")) {
        onSearch(searchParams.get("waybill_number"));
      }
    }
    fetchData();
  }, [onSearch, searchParams]);

  const isAlreadySigned = useMemo(() => {
    if (!data) {
      return false;
    }
    if (data.logs.find((step) => step.status === "SIGNED")) {
      return true;
    }
    return false;
  }, [data]);

  
  const getCalculatedDay = (data) => {
    if (data.estimatedTime) {
      var result = new Date(data.waybill_info?.createdAt);
      result.setDate(result.getDate() + Number(data.estimatedTime?.remark));
      return moment(result).format("YYYY-MM-DD");
    }
  };
  const getStepTitle = (status) => {
    switch (status) {
      case "ORDERCREATED":
        return "Prepare Order Form";

      case "WAYBILLCREATED":
        return "Received at Warehouse";

      case "LOADED":
        return "Cargo Loading";

      case "UNLOADED":
        return "Arrived at Warehouse";

      case "SIGNED":
        return "Signed";
      case "COMMENT":
        return "Waybill Status";

      default:
        return "";
    }
  };

  const getStepIcon = (status) => {
    switch (status) {
      case "ORDERCREATED":
        return <OrderSvg style={{ width: 35, height: 35 }} />;

      case "WAYBILLCREATED":
        return <WaybillSvg style={{ width: 35, height: 35 }} />;

      case "LOADED":
        return <ShipSvg style={{ width: 35, height: 35 }} />;
      case "UNLOADED":
        return <WarehouseSvg style={{ width: 35, height: 35 }} />;

      case "SIGNED":
        return <SignSvg style={{ width: 35, height: 35 }} />;
      case "COMMENT":
        return <SignSvg style={{ width: 35, height: 35 }} />;

      default:
        return "";
    }
  };

  const getTrackingDate = (status, stepData) => {
    switch (status) {
      case "ORDERCREATED":
        return moment(data.waybill_info?.order_id?.shipping_date).format("D/MMM/YY");

      case "WAYBILLCREATED":
        return moment(data.waybill_info?.shipping_date).format("D/MMM/YY");

      case "LOADED":
        return moment(stepData.way?.date).format("D/MMM/YY");

      case "UNLOADED":
        return moment(stepData.way?.date).format("D/MMM/YY");

      case "SIGNED":
        return moment(stepData.sign?.date).format("D/MMM/YY");

      default:
        return "";
    }
  };

  const getSubStepTitle = (status, stepData) => {
    switch (status) {
      case "ORDERCREATED":
        return data.createdBranch?.name;

      case "WAYBILLCREATED":
        return data.createdBranch?.name;

      case "LOADED":
        return ``;

      case "UNLOADED":
        return stepData?.info?.destination?.name;

      case "SIGNED":
        return `Signed by ${stepData?.info?.sign_info?.name}`;
      case "COMMENT":
        return stepData.content;

      default:
        return "";
    }
  };

  const generatedLogs = useMemo(() => {
    if (data) {
      let existed = [];
      let logs = [];
      for (var i = data.logs.length; i > 0; i--) {
        const log = data.logs[i - 1];
        if (log.status === "UNLOADED") {
          if (log?.info?.destination?._id === data.destination._id) {
            const alreadyUnloaded = existed.find(
              (e) => e.status === "UNLOADED"
            );
            if (alreadyUnloaded) {
              existed = existed.filter((e) => e.status !== "UNLOADED");
              logs = logs.filter((e) => e.status !== "UNLOADED");
            }

            existed.push(log);
            logs.push(log);
          }
        }
        if (!existed.find((e) => e.status === log.status)) {
          if (log.status !== "UNLOADED") {
            existed.push(log);
            logs.push(log);
          }
        }
      }
      return logs;
    }
    return [];
  }, [data]);

  return (
    <div className="px-md-5 px-3">
      <Container className="p-md-5 p-4">
        <Row className={styles.header}>
          <h5>{t("tracking.shipment")}</h5>
          <span>{t("tracking.full_container")}</span>
        </Row>
        <Row className="my-4">
          <Col lg={4} md={6} className={styles.track_input}>
            <div>
              <Form.Control
                placeholder="Waybill number"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />
              <span>{t("tracking.waybill_number")}</span>
            </div>
          </Col>
          <Col className={styles.track_input}>
            <Button
              style={{ backgroundColor: "#1A2753", height: 32, fontSize: 12 }}
              className="ms-md-3"
              onClick={onSearchData}
            >
              {loading ? "Tracking.." : "Track"}
            </Button>
          </Col>
        </Row>
        {loading ? (
          <Row
            style={{ paddingTop: 20, paddingBottom: 20, textAlign: "center" }}
          >
            Loading
          </Row>
        ) : (
          <>
            {notFound && (
              <Row
                style={{
                  paddingTop: 50,
                  paddingBottom: 50,
                  textAlign: "center",
                }}
              >
                <h4 style={{ color: "red" }}>Waybill Number Not Found</h4>
              </Row>
            )}
            {!notFound && data && (
              <>
                <Row className={styles.waybill_info}>
                  <Col md={6}>
                    <span>Waybill number</span>
                    <h6>
                      {data.waybillnumber} (
                      {getStepTitle(generatedLogs[0].status)})
                    </h6>
                  </Col>
                  <Col md={3}>
                    <span>From</span>
                    <h6>{data.createdBranch?.name}</h6>
                  </Col>
                  <Col md={3}>
                    <span>To</span>
                    <h6>{data.destination?.name}</h6>
                  </Col>
                </Row>

                <Row className={styles.divider}></Row>

                <Row className={styles.info_details}>
                  {data.estimatedTime && !isAlreadySigned && (
                    <Col md={12} className={styles.date_box}>
                      <div className={styles.icon}>
                        <RiShip2Fill />
                      </div>
                      <div className="d-flex flex-column">
                        <span>Estimated arrival date</span>
                        <span>{getCalculatedDay(data)}</span>
                      </div>
                    </Col>
                  )}
                  <Col md={7} className={styles.info_details_left}>
                    <Row className="mb-4">
                      <Row className={styles.info_row_header}>
                        <Col md={6}>
                          <span>Shipper</span>
                        </Col>
                        <Col md={6}>
                          <span>Consignee</span>
                        </Col>
                      </Row>

                      <Row className={styles.info_row_content}>
                        <Col md={6}>
                          <span>{data?.sender?.name}</span>
                        </Col>
                        <Col md={6}>
                          <span>{data?.receiver?.name}</span>
                        </Col>
                      </Row>
                    </Row>
                    <Row className="mb-3">
                      <Row className={styles.info_row_header}>
                        <Col md={6}>
                          <span>Basic Information</span>
                        </Col>
                      </Row>

                      <Row className={styles.info_row_content}>
                        <Col md={6}>
                          <span>
                            Shipment type:{" "}
                            {data?.waybill_info?.shipment_type?.name}
                          </span>
                        </Col>
                        <Col md={6}>
                          <span>
                            Shipping mode:{" "}
                            {data?.waybill_info?.shipping_mode?.name}
                          </span>
                        </Col>
                      </Row>
                    </Row>
                    <Row>
                      <Row className={styles.info_row_header}>
                        <Col md={6}>
                          <span>Cargo Information</span>
                        </Col>
                      </Row>
                      <Row className={styles.info_row_content}>
                        <Col md={6}>
                          <span>
                            Cargo name : {data?.waybill_info?.item?.itemname}
                          </span>
                        </Col>
                      </Row>
                      <Row className={styles.info_row_content}>
                        <Col md={6}>
                          <span>
                            {" "}
                            Packing quantity : {data?.waybill_info?.item?.qty}
                            CTN
                          </span>
                        </Col>
                        <Col md={6}>
                          <span>
                            Weight : {data?.waybill_info?.item?.weight} KG{" "}
                          </span>
                        </Col>
                      </Row>
                      <Row className={styles.info_row_content}>
                        <Col md={6}>
                          <span>
                            {" "}
                            Measurement : {data?.waybill_info?.item?.volume}CBM
                          </span>
                        </Col>
                      </Row>
                    </Row>
                  </Col>
                  <Col md={5} className={styles.info_details_right}>
                    <Container>
                      <nav className="nav nav-pills flex-column">
                        {generatedLogs.map((step) => (
                          <a className="nav-link" href="#">
                            <div className="nav-container">
                              <div className="icon">
                                {getStepIcon(step.status)}
                              </div>
                            </div>
                            <div className="right-info">
                              <span>
                                {getStepTitle(step.status)} -{" "}
                                {getSubStepTitle(step.status, step)}
                              </span>
                              <p> {getTrackingDate(step.status, step)}</p>
                            </div>
                          </a>
                        ))}
                      </nav>
                    </Container>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Container>
    </div>
  );
};

export default TrackingPage;

import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  Input,
} from "reactstrap";
import { useForm } from "react-hook-form";
import TextBox from "../Inputs/TextBox";
import {  URL1 } from './../../commons/constants';
import Cancel from "../../assets/imgs/EF Branding Website/1891023_cancel_cercle_close_delete_dismiss_icon.png";
import * as styles from "../../styles/HomePageStyle.module.css";

function GetQuote({ isOpen, toggle, msgToggle }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [prefer_contact, setPreferContact] = useState(null);
  const [prefer_contact_error, setPreferContactError] = useState(false);
  const submitData = async (values) => {
    if (checkFields()) {
      values.prefer_contact = prefer_contact;
      toggle();
      try {
        await fetch(`${URL1}/frontend/quote`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(values),
        });
        reset();
        msgToggle();
      } catch (err) {
        alert("Error requesting quote");
      }
    };
  }
  const checkFields = () => {
    if (!prefer_contact) {
      setPreferContactError(true);
      return false;
    }
    setPreferContactError(false);
    return true;
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        size="lg"
        className={styles.modal_box}
      >
        <ModalBody>
          <div className="d-flex justify-content-end">
            <div className="col-12">
              <h4 className="text-center ms-5 fw-bold py-3">
                Quote Request Form
              </h4>
            </div>
            <img
              src={Cancel}
              width={30}
              height={30}
              alt=""
              onClick={toggle}
              style={{ cursor: "pointer" }}
            />
          </div>
          <div>
            <Form
              className="p-lg-4 p-md-4 p-sm-1"
              onSubmit={handleSubmit(submitData)}
            >
              <Row className="d-flex justify-content-between">
                <FormGroup className="col-lg-6 col-md-6 col-sm-12">
                  <Label className="text-black mb-lg-3 mb-md-3 md-sm-0">
                    Full Name <sup className="text-danger">*</sup>
                  </Label>
                  <div lg={10}>
                    <TextBox
                      className="form-control"
                      type="text"
                      registerProps={register("name", {
                        required: "full name is required",
                      })}
                      errors={errors.name}
                    />
                  </div>
                </FormGroup>
                <FormGroup className="col-lg-6 col-md-6 col-sm-12">
                  <Label className=" text-black  mb-lg-3">
                    Company/Business Name <sup className="text-danger">*</sup>
                  </Label>
                  <div lg={10}>
                    <TextBox
                      className="form-control"
                      type="text"
                      registerProps={register("company", {
                        required: "company name is required",
                      })}
                      errors={errors.company}
                    />
                  </div>
                </FormGroup>
              </Row>
              <Row className="d-flex justify-content-between mt-lg-3">
                <FormGroup className="d-flex flex-column col-lg-6 col-md-6 col-sm-12">
                  <div className="mb-3">
                    <Label className="pe-5 text-black  mt-2">
                      Email <sup className="text-danger">*</sup>
                    </Label>
                    <div lg={10}>
                      <TextBox
                        className="form-control"
                        type="text"
                        registerProps={register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "invalid email address",
                          },
                        })}
                        errors={errors.email}
                      />
                    </div>
                  </div>
                  <div>
                    <Label className="pe-5 text-black  mt-lg-2">
                      Phone Number <sup className="text-danger">*</sup>
                    </Label>
                    <div lg={10}>
                      <TextBox
                        className="form-control"
                        dd
                        type="text"
                        registerProps={register("phone", {
                          required: "Phone Number is required",
                        })}
                        errors={errors.phone}
                      />
                    </div>
                  </div>
                </FormGroup>
                <FormGroup className="col-lg-6 col-md-6 col-sm-12">
                  <Label className=" text-black  mb-lg-3">
                    Please tell us a little about you and/or your business
                    <br></br>
                    <span className="text-danger">
                      Do not afraid to be specific!
                    </span>
                  </Label>
                  <TextBox
                    className="form-control"
                    type="textarea"
                    errors={errors.feedback}
                  />
                </FormGroup>
              </Row>
              <Row className="mt-3">
                <Col>
                  <FormGroup>
                    <label className="mb-2">
                      Preferred Contact Method{" "}
                      <sup className="text-danger">*</sup>
                    </label>
                    <div className="d-flex">
                      <div className="radio-button me-4">
                        <FormGroup check>
                          <Input
                            name="prefer_contact"
                            type="radio"
                            value={"phone"}
                            checked={prefer_contact === "phone"}
                            ßß
                            onChange={(e) => setPreferContact(e.target.value)}
                          />
                          <span className="ms-2">Phone</span>
                        </FormGroup>
                      </div>
                      <div className="radio-button">
                        <FormGroup check>
                          <Input
                            name="prefer_contact"
                            type="radio"
                            value={"email"}
                            checked={prefer_contact === "email"}
                            ßß
                            onChange={(e) => setPreferContact(e.target.value)}
                          />
                          <span className="ms-2">Email</span>
                        </FormGroup>
                      </div>
                    </div>
                  </FormGroup>
                  {prefer_contact_error && (
                    <span className="text-danger mt-4">
                      Please choose preferred contact method.
                    </span>
                  )}
                </Col>
              </Row>
              <div className="d-flex justify-content-end">
                <Button
                  className="btn-sm px-5 py-2 border-0 shadow-0 text-capitalize"
                  style={{
                    borderRadius: "0px",
                    background: "#1a2753",
                    color: "white",
                  }}
                  type="submit"
                  onClick={checkFields}
                >
                  Get Quote Now
                </Button>
              </div>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default GetQuote;

import React from "react";
import styles from "../styles/ContactUsPageStyle.module.css";
import { Container, Row } from "react-bootstrap";
import { FiShare2 } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { BiPhoneCall } from "react-icons/bi";
import { BsEnvelope } from "react-icons/bs";
import { TiSocialFacebook, TiSocialTwitter } from "react-icons/ti";
import { useTranslation } from "react-i18next";

const ContactPage = () => {
  const { t } = useTranslation();
  return (
    <div>
      <section>
        <Container>
          <Row className="py-5" style={{ marginBottom: "40px" }}>
            <div className={styles.office_details}>
              <div className="col-xl-8 col-md-12 mb-5">
                <h2>{t("contact.contact")}</h2>
                <h5>
                  <HiOutlineLocationMarker className={styles.icon_design} />
                  Visit our office
                </h5>
                <p>
                  <span className="fw-bolder">{t("contact.yangon_title")}</span>{" "}
                  <br /> {t("contact.yangon")}
                  <br /> {t("contact.yangon_phone")}
                </p>
                <p>
                  <span className="fw-bolder">
                    {" "}
                    {t("contact.dongguan_title")}
                  </span>
                  <br />
                  {t("contact.dongguan")}
                  <br /> {t("contact.dongguan_phone")}
                </p>
                <p>
                  <span className="fw-bolder"> {t("contact.yiwu_title")}</span>
                  <br />
                  {t("contact.yiwu")}
                  <br /> {t("contact.yiwu_phone")}
                </p>
              </div>
              <div className="col-xl-4 col-md-12 mb-5">
                <img
                  src="https://ak.picdn.net/offset/photos/6037bb6c6f52af4409fdbdf1/medium/offset_1100938.jpg"
                  alt=""
                  className={styles.image}
                />
              </div>
            </div>
            <div className={styles.contact_details}>
              <div className="col-xl-4 col-md-4 col-12">
                <h5>
                  <BiPhoneCall className={styles.icon_design} /> Call us on
                </h5>
                <div className={styles.phones}>
                  <p>
                    <span>Hotline phone</span>
                    <span>Customer service</span>
                  </p>
                  <p>
                    <span>:</span>
                    <span>:</span>
                  </p>
                  <p>
                    <span>+9599406669868</span>
                    <span>+959266961669</span>
                  </p>
                </div>
              </div>
              <div className=" col-xl-4 col-md-4 col-12 d-flex justify-content-xl-center">
                <BsEnvelope className={styles.icon_design} />
                <p className={styles.email}>
                  <h5>{t("contact.email")}</h5>
                  <span>marketing@efilservice.com</span>
                </p>
              </div>
              <div className="col-xl-4 col-md-4 col-12  d-flex justify-content-xl-center">
                <FiShare2 className={styles.icon_design} />
                <div className={styles.email}>
                  <h5>{t("contact.facebook")}</h5>
                  <div className="p-0 m-0">
                    <TiSocialFacebook
                      style={{ cursor: "pointer" }}
                      className={styles.icon_circle_design}
                      onClick={() => {
                        window.location =
                          "https://www.facebook.com/easyfreightintlogistics";
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </section>
      {/* <section className={`${styles.content} ${styles.banner_image}`}>
        <div className={styles.backdrop}></div>
      </section> */}
    </div>
  );
};
export default ContactPage;

import logo from "../../assets/imgs/easyfmm_logo.png";
import { useState } from "react";
import { Container, Navbar, Nav, NavDropdown, Button } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import "./navbar.css";
import GetQuote from "./GetQuote";
import ThankMsg from "./ThankMsg";
import { useTranslation } from "react-i18next";

const NavBarComponent = () => {
  // const style = {
  //   textDecoration: "none",
  //   color: "#333",
  // }
  // const [show, setShow] = useState(false);
  const [fixed, setFixed] = useState(false);
  const { i18n, t } = useTranslation();

  window.addEventListener("scroll", function () {
    if (window.scrollY > 300) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  });

  const LANGS = [
    {
      value: "en",
      label: "English",
      icon: "/flags/en.jpg",
    },
    {
      value: "thai",
      label: "Thailand",
      icon: "/flags/thai.png",
    },
  ];

  const routes = [
    {
      name: t("home.home"),
      link: "/",
    },
    {
      name: t("tracking.tracking"),
      link: "/tracking",
    },
    {
      name: t("services.services"),
      link: "/services",
    },
    {
      name: t("about.about"),
      link: "/about-us",
    },
    {
      name: t("contact.contact"),
      link: "/contact-us",
    },
    {
      name: "FAQ",
      link: "/FAQ",
    },
  ];
  const [isOpen, setIsOpen] = useState(false);
  const [isMsgOpen, setIsMsgOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const msgToggle = () => {
    setIsMsgOpen(!isMsgOpen);
  };

  const location = useLocation();

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <>
      <Navbar
        bg="light"
        expand="lg"
        fixed={fixed ? "top" : ""}
        className="py-4"
      >
        <Container>
          <div>
            <Navbar.Brand>
              <NavLink to="/">
                <img src={logo} alt="logo" className="navbar-icon" />
              </NavLink>
            </Navbar.Brand>
          </div>
          <div>
            <div>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <div className="navbar-collapse">
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="me-auto">
                    {routes.map((route, index) => (
                      <Nav.Link key={`nav-key-${index}`}>
                        <span
                          className={`link-style ${
                            route.link === location.pathname ? "active" : ""
                          }`}
                          onClick={() => (window.location = route.link)}
                        >
                          {route.name}
                        </span>
                      </Nav.Link>
                    ))}
                    <NavDropdown
                      title="Languages"
                      id="basic-nav-dropdown"
                      className="link-style"
                    >
                      {LANGS.map((lang) => (
                        <NavDropdown.Item
                          href="#action/3.1"
                          className="d-flex justify-content-around"
                          onClick={() => handleLanguageChange(lang.value)}
                        >
                          {lang.label}
                          <img
                            src={lang.icon}
                            alt="flag"
                            width={40}
                            height={20}
                          />
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                    <Nav.Link className="p-0 m-0 pb-3">
                      <Button
                        style={{ backgroundColor: "#1A2753" }}
                        className=" d-lg-block"
                        onClick={toggle}
                      >
                        Get a Quote
                      </Button>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </div>
            </div>
          </div>
        </Container>
      </Navbar>

      <GetQuote isOpen={isOpen} toggle={toggle} msgToggle={msgToggle} />
      <ThankMsg msgToggle={msgToggle} isMsgOpen={isMsgOpen} />
    </>
  );
};

export default NavBarComponent;

import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
} from "reactstrap";
import * as styles from "../../styles/HomePageStyle.module.css";

function ThankMsg({ isMsgOpen, msgToggle }) {
  return (
    <div>
      <Modal
        isOpen={isMsgOpen}
        toggle={msgToggle}
        size="md"
        className={styles.thz_modal_box}
        // style={{ top: "15%", borderRadius: "30px" }}
      >
        <ModalBody>
          <div className="d-flex  px-lg-5 px-md-5 px-sm-2 py-3 flex-column">
            <h5 className="fw-bold mb-3 text-center">Thank you for your interest !!</h5>
            <p className="mb-3 text-center">
              You have successfully requested the quote and our team will get
              back to you soon.
            </p>
            <div className="d-flex justify-content-end mt-3">
              <Button
                className="btn-sm px-5 py-2 border-0 shadow-0 text-capitalize"
                style={{
                  borderRadius: "0px",
                  background: "#1a2753",
                  color: "white",
                }}
                onClick={msgToggle}
              >
                OK
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ThankMsg;

import React from "react";
import styles from "../../styles/FaqPageStyle.module.css";
import { Card, CardBody, Collapse } from "reactstrap";
import { useState } from "react";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import Question from "../../assets/imgs/EF Branding Website/334731_quora_icon.png";
import Answer from "../../assets/imgs/EF Branding Website/letter-a (1).png";
import { useTranslation } from "react-i18next";
const QuesFive = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center">
        <img src={Question} alt="" style={{ width: "30px", height: "30px" }} />
        <button id="toggler5" className={styles.btn} onClick={toggle}>
          <span style={{ textAlign: "left" }}> {t("faq.q_five")}</span>

          {!isOpen ? (
            <div className={styles.icon_container}>
              <AiOutlinePlusCircle className={styles.icon} />
            </div>
          ) : (
            <div className={styles.icon_container}>
              <AiOutlineMinusCircle className={styles.icon} />
            </div>
          )}
        </button>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="d-flex mt-3">
          <img src={Answer} alt="" className={styles.img} />
          <Card style={{ width: "100%" }}>
            <CardBody>
              <ul>
                <li className="mb-2">{t("faq.q_five_ans_one")}</li>
                <li className="mb-2">{t("faq.q_five_ans_two")}</li>
                <li className="mb-2">{t("faq.q_five_ans_three")}</li>
                <li className="mb-2">{t("faq.q_five_ans_four")}</li>
                <li className="mb-2">{t("faq.q_five_ans_five")}</li>
                <li className="mb-2">{t("faq.q_five_ans_six")}</li>
                <li className="mb-2">{t("faq.q_five_ans_seven")}</li>
                <li className="mb-2">{t("faq.q_five_ans_eight")}</li>
                <li className="mb-2">{t("faq.q_five_ans_nine")}</li>
                <li className="mb-2">{t("faq.q_five_ans_ten")}</li>
                <li className="mb-2">{t("faq.q_five_ans_eleven")}</li>
                <li className="mb-2">{t("faq.q_five_ans_twelve")}</li>
                <li className="mb-2">{t("faq.q_five_ans_thirteen")}</li>
                <li className="mb-2">{t("faq.q_five_ans_fourteen")}</li>
                <li className="mb-2">{t("faq.q_five_ans_fifteen")}</li>
                <li className="mb-2">{t("faq.q_five_ans_sixteen")}</li>
                <li className="mb-2">{t("faq.q_five_ans_seventeen")}</li>
                <li className="mb-2">{t("faq.q_five_ans_eighteen")}</li>
                <li className="mb-2">{t("faq.q_five_ans_nineteen")}</li>
              </ul>
            </CardBody>
          </Card>
        </div>
      </Collapse>
    </div>
  );
};
export default QuesFive;

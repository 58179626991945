import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FooterComponent from "./components/Footer/FooterComponent";
import NavBarComponent from "./components/NavBar/NavBarComponent";
import ScrollToTop from "./components/ScrollToTop";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import HomePage from "./pages/HomePage";
import TrackingPage from "./pages/TrackingPage";
import ServicePage from "./pages/ServicePage";
import FaqPage from "./pages/faq";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  return (
    <Router>
      <header>
        <NavBarComponent />
      </header>
      <main>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/tracking" element={<TrackingPage />} />
          <Route path="/services" element={<ServicePage />} />
          <Route path="/about-us" element={<AboutPage />} />
          <Route path="/contact-us" element={<ContactPage />} />
          <Route path="/FAQ" element={<FaqPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </main>
      <footer>
        <FooterComponent />
      </footer>
    </Router>
  );
}

export default App;

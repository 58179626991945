import React from "react";
import styles from "../styles/PrivayPolicyStyle.module.css";
import { Container, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.content}>
      <div className={styles.backdrop}>
        <Container style={{ border: "2px solid transparent" }}>
          <h4 className="fw-bolder py-5 text-center">{t("privacy.title")}</h4>
          <p>{t("privacy.subtitle")}</p>
          <div className="my-5">
            <span className="fw-bolder">{t("privacy.collection")}</span>
            <div className={styles.divider}></div>
            <p className="mt-3">{t("privacy.collection_detail")}</p>
          </div>
          <div className="my-5">
            <span className="fw-bolder">{t("privacy.data")}</span>
            <div className={styles.divider}></div>
            <p className="mt-3">{t("privacy.data_detail")}</p>
          </div>
          <div className="my-5">
            <span className="fw-bolder">{t("privacy.personal")}</span>
            <div className={styles.divider}></div>
            <p className="mt-3">{t("privacy.personal_detail")}</p>
          </div>
          <div className="my-5">
            <span className="fw-bolder">{t("privacy.accuracy")}</span>
            <div className={styles.divider}></div>
            <p className="mt-3">{t("privacy.accuracy_detail")}</p>
          </div>
          <div className="my-5">
            <span className="fw-bolder">{t("privacy.limited")}</span>
            <div className={styles.divider}></div>
            <p className="mt-3">{t("privacy.limited_detail")}</p>
          </div>
          <div className="my-5">
            <span className="fw-bolder">{t("privacy.management")}</span>
            <div className={styles.divider}></div>
            <p className="mt-3">{t("privacy.management_detail")}</p>
          </div>
          <div>
            <span className="fw-bolder">{t("privacy.provision")}</span>
            <div className={styles.divider}></div>
            <p className="mt-3">{t("privacy.provision_detail")}</p>
          </div>
        </Container>
      </div>
    </div>
  );
};
export default PrivacyPolicy;

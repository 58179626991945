import React from "react";
import { InputGroupAddon, InputGroupText, InputGroup, Input } from "reactstrap";
import "./text-box.scss";
function TextBox({
  icon,
  prepend = false,
  errors,
  customErrorMessage = "",
  registerProps,
  onChange,
  ...props
}) {
  return (
    <>
      <InputGroup className="custom-tb input-group-merge input-group-alternative border-0">
        {prepend && (
          <Input addonType="prepend">
            <InputGroupText>{icon}</InputGroupText>
          </Input>
        )}

        {props.type === "textarea" ? (
          <textarea
            className="form-control "
            {...props}
            {...registerProps}
            autoComplete={"off"}
            style={{ minHeight: 100, borderRadius: "0px" }}
          ></textarea>
        ) : (
          <input
            className="form-control"
            {...props}
            {...registerProps}
            autoComplete={"off"}
            onChange={onChange}
            style={{ height: "45px", borderRadius: "0px" }}
          />
        )}
      </InputGroup>
      {errors && (
        <div
          className="error-containe"
          style={{ marginTop: props.type === "textarea" ? 10 : 5 }}
        >
          <span className="text-danger">{errors?.message}</span>
        </div>
      )}
      {errors && errors.type === "validate" && (
        <div className="error-container">
          <span className="text-danger">{customErrorMessage}</span>
        </div>
      )}
    </>
  );
}
export default TextBox;

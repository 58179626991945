import React from "react";
import { Col, Container, Row } from "reactstrap";
import styles from "../styles/AboutPageStyle.module.css";
import ExportedShipping from "./../assets/imgs/ExportShipping.jpg";
import Ship from "../assets/imgs/EF Branding Website/aerial-view-container-cargo-ship-sea.jpg";
import Shelf from "../assets/imgs/EF Branding Website/distribution-warehouse-building-interior-large-storage-area-with-goods-shelf.jpg";
import Strength from "../assets/imgs/EF Branding Website/strength-people-hands-success-meeting.jpg";
import Man from "../assets/imgs/EF Branding Website/handsome-young-man-working-with-papers.jpg";
import Woman from "../assets/imgs/EF Branding Website/beautiful-smart-asian-young-entrepreneur-business-woman-owner-sme-online-checking-product (4).jpg";
import Global from "../assets/imgs/EF Branding Website/240_F_443956484_YbGZYCLvZC0f6v2kBpNLGjpO87fCm7SI.jpg";
import { useTranslation } from "react-i18next";
const AboutPage = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Container className={styles.about_us_container}>
        <Row>
          <Row className="py-5 mb-5">
            <Col md={6} className={styles.about_us}>
              <h5 className="fw-bold">{t("about.about")}</h5>
              <p>{t("about.about_one")}</p>
              <p>{t("about.about_two")}</p>
            </Col>
            <Col md={6} className="my-sm-5">
              <div className="d-flex justify-content-end position-relative ">
                <img src={Ship} className={styles.absolute_img} />
                <div className={styles.about_us_img}>
                  <img src={Shelf} className={styles.absolute_img} />
                </div>
              </div>
            </Col>
          </Row>
          <Row className="py-5 justify-content-center ms-1">
            <h5 className="text-center fw-bold mt-5">{t("about.mission")}</h5>
            <Row className="py-5 py-sm-0 d-flex mb-3 mt-5 justify-content-sm-center">
              <Col className="d-flex justify-content-center align-items-center position-relative">
                <div className={styles.background}></div>
                <img src={Strength} className={styles.about_img_second} />
              </Col>
              <Col className="d-flex justify-content-end align-items-center">
                <p
                  className={styles.padding_one}
                  // style={{ paddingLeft: "12%" }}
                >
                  {t("about.mission_one")}
                </p>
              </Col>
            </Row>
            <Row className="py-5 d-flex mb-3 justify-content-sm-center flex-column-reverse flex-lg-row flex-md-row">
              <Col className="d-flex justify-content-left align-items-center ">
                <p
                  className={styles.padding_one}
                  // style={{ paddingLeft: "12%" }}
                >
                  {t("about.mission_two")}
                </p>
              </Col>
              <Col className="d-flex justify-content-center align-items-center position-relative">
                <div className={styles.background_right}></div>
                <img src={Woman} className={styles.about_img_second} />
              </Col>
            </Row>
            <Row className="py-5 py-sm-0 d-flex mb-3 justify-content-sm-center">
              <Col className="d-flex justify-content-center align-items-center position-relative">
                <div className={styles.background}></div>
                <img src={Man} className={styles.about_img_second} />
              </Col>
              <Col className="d-flex justify-content-center align-items-center">
                <p
                  className={styles.padding_two}
                  // className="fw-bold text-center"
                  // style={{ paddingRight: "8%" }}
                >
                  {t("about.mission_three")}
                </p>
              </Col>
            </Row>
            <Row className="py-5 py-sm-0 d-flex mb-3 justify-content-sm-center flex-column-reverse flex-lg-row flex-md-row">
              <Col className="d-flex justify-content-center align-items-center ">
                <p
                  className={styles.padding_three}
                  // className="fw-bold text-center"
                  // style={{ paddingLeft: "10%" }}
                >
                  {t("about.mission_four")}
                </p>
              </Col>
              <Col className="d-flex justify-content-center align-items-center position-relative">
                <div className={styles.background_right}></div>
                <img src={Global} className={styles.about_img_second} />
              </Col>
            </Row>
          </Row>
        </Row>
      </Container>
      <Container fluid>
        <Row className={styles.vision}>
          <h5 className="mb-5">{t("about.vision")}</h5>
          <p>{t("about.vision_detail")}</p>
        </Row>
        <Row className="py-5">
          <h5 className="text-center fw-bold mb-lg-5">
            {t("about.our_value")}
          </h5>
          <div className="d-flex flex-wrap mt-5 justify-content-center justify-content-lg-start">
            <Col
              md={2}
              className="d-flex justify-content-center py-5 py-sm-0 position-relative me-2 me-lg-0 me-md-0"
            >
              <div className={styles.values_background}></div>
              <div className={styles.values}>{t("about.our_value_one")}</div>
            </Col>
            <Col
              md={2}
              className="d-flex justify-content-center py-5 py-sm-0 position-relative"
            >
              <div className={styles.values_background}></div>
              <div className={styles.values}>{t("about.our_value_two")}</div>
            </Col>
            <Col
              md={2}
              className="d-flex justify-content-center py-5 py-sm-0 position-relative me-2 me-lg-0 me-md-0"
            >
              <div className={styles.values_background}></div>
              <div className={styles.values}>{t("about.our_value_three")}</div>
            </Col>
            <Col
              md={2}
              className="d-flex justify-content-center py-5 py-sm-0 position-relative"
            >
              <div className={styles.values_background}></div>
              <div className={styles.values}>{t("about.our_value_four")}</div>
            </Col>
            <Col
              md={2}
              className="d-flex justify-content-center py-5 py-sm-0 position-relative me-2 me-lg-0 me-md-0"
            >
              <div className={styles.values_background}></div>
              <div className={styles.values}>{t("about.our_value_five")}</div>
            </Col>
            <Col
              md={2}
              className="d-flex justify-content-center py-5 py-sm-0 position-relative"
            >
              <div className={styles.values_background}></div>
              <div className={styles.values}>
                <div className="d-flex flex-column text-center">
                  <span>{t("about.our_value_six")}</span>
                  <span>{t("about.our_value_seven")}</span>
                </div>
              </div>
            </Col>
          </div>
        </Row>
      </Container>{" "}
    </div>
  );
};
export default AboutPage;

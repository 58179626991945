import home from "./home.json";
import tracking from "./tracking.json";
import services from "./services.json";
import about from "./about.json";
import faq from "./faq.json";
import contact from "./contact.json";
import privacy from "./privacy.json";

export const thai = {
  home,
  tracking,
  services,
  about,
  faq,
  contact,
  privacy,
};

import { Col, Container, Row } from "react-bootstrap";
import { Form, FormGroup, Label, Button } from "reactstrap";
import logo from "../../assets/imgs/footer_logo.png";
import {
  BsFacebook,
  BsTwitter,
  BsYoutube,
  BsLinkedin,
  BsInstagram,
} from "react-icons/bs";
import { useForm } from "react-hook-form";
import TextBox from "../Inputs/TextBox";
import { useNavigate } from "react-router-dom";
import "./footer.scss";
import { useTranslation } from "react-i18next";
const FooterComponent = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const { t } = useTranslation();

  const submitData = async (values) => {
    try {
      await fetch(`${process.env.REACT_APP_BASE_URL}/frontend/suggestion`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });
      reset();
      alert("We will reply u back. Thanks for your suggest");
    } catch (err) {
      alert("Error sending suggestion");
    }
  };
  return (
    <Container className="pt-3 ">
      <Row className="mt-4">
        <Col
          sm={6}
          md={3}
          lg={3}
          xs={6}
          className="d-flex justify-content-center"
        >
          <h5
            onClick={() => (window.location = "/services")}
            className="fw-bold footer-link"
            style={{ cursor: "pointer" }}
          >
            {t("services.services")}
          </h5>
          <hr className="d-block d-md-none" />
        </Col>
        <Col
          xs={6}
          sm={6}
          md={3}
          lg={3}
          className="d-flex justify-content-center"
        >
          <h5
            onClick={() => (window.location = "/about-us")}
            className="fw-bold footer-link"
            style={{ cursor: "pointer" }}
          >
            {t("about.about")}
          </h5>
          <hr className="d-block d-md-none" />
        </Col>

        <Col
          xs={6}
          sm={6}
          md={3}
          lg={3}
          className="d-flex justify-content-center"
        >
          <h5 className="fw-bold footer-link" style={{ cursor: "pointer" }}>
            <a
              href="/privacy-policy"
              style={{ textDecoration: "none", color: "white" }}
            >
              {t("privacy.privacy")}
            </a>
          </h5>
          <hr className="d-block d-md-none" />
        </Col>
        <Col
          xs={6}
          sm={6}
          md={3}
          lg={3}
          className="d-flex justify-content-center"
        >
          <h5
            className="footer-link"
            style={{ cursor: "pointer" }}
            onClick={() => (window.location = "/faq")}
          >
            FAQ
          </h5>
          <hr className="d-block d-md-none" />
        </Col>
      </Row>
      <Row className="py-5 ">
        <Col lg={4} className=" d-flex justify-content-around flex-column">
          <Row>
            <Col className="d-flex justify-content-center">
              <img src={logo} alt="logo" className="footer-img" />
            </Col>
          </Row>
          <Row className="contact-info">
            <h4 style={{ cursor: "pointer" }}>Contact Us</h4>
            <div className="d-flex  mb-2">
              <span className="contact-ways">Gmail</span>
              <span>: marketing@efilservice.com</span>
            </div>

            <div className="d-flex  mb-2">
              <span className="contact-ways">Hotline Number</span>
              <span>: +959 940 669 868</span>
            </div>
            <div className="d-flex  mb-2">
              <span className="contact-ways">Customer Service</span>
              <span>: +959 266 961 669</span>
            </div>
          </Row>
          <Row className="contact-info">
            <h4>Social</h4>

            <ul className="d-flex">
              <li className="me-3">
                <BsFacebook
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    window.location =
                      "https://www.facebook.com/easyfreightintlogistics";
                  }}
                />
              </li>
            </ul>
          </Row>
        </Col>
        <Col lg={8}>
          <Form
            style={{ backgroundColor: "#DDDDDD" }}
            className="p-4"
            onSubmit={handleSubmit(submitData)}
          >
            <h4 className="text-black my-3">Thanks for sharing your ideas</h4>
            <FormGroup className="mb-3">
              <Label className="text-black mb-3">
                Please describe your feedback,suggestion or any idea in the box
                below.
              </Label>
              <TextBox
                className="form-control"
                type="textarea"
                registerProps={register("suggestion", {
                  required: "Suggestion is required",
                })}
                errors={errors.suggestion}
              />
            </FormGroup>
            <FormGroup className="d-flex mb-3">
              <Label className="pe-5 text-black fw-bold mt-2">Name</Label>
              <div style={{ width: "100%" }}>
                <TextBox
                  className="form-control"
                  type="text"
                  registerProps={register("name", {
                    required: "Name is required",
                  })}
                  errors={errors.name}
                />
              </div>
            </FormGroup>
            <FormGroup className="d-flex mb-3">
              <Label className="pe-5 text-black fw-bold mt-2">Email</Label>
              <div style={{ width: "100%" }}>
                <TextBox
                  className="form-control"
                  type="text"
                  registerProps={register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  })}
                  errors={errors.email}
                />
              </div>
            </FormGroup>
            <div className="d-flex justify-content-end">
              <Button
                className="btn-sm  fw-bold border-0 shadow-0"
                style={{
                  borderRadius: "0px",
                  background: "#2B5188",
                  color: "white",
                }}
                type="submit"
              >
                Submit
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default FooterComponent;

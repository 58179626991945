import React from "react";
import styles from "../../styles/FaqPageStyle.module.css";
import { Card, CardBody, Collapse } from "reactstrap";
import { useState } from "react";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import Question from "../../assets/imgs/EF Branding Website/334731_quora_icon.png";
import Answer from "../../assets/imgs/EF Branding Website/letter-a (1).png";
import { useTranslation } from "react-i18next";

const QuesOne = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggle = (event) => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      <div className="d-flex justify-content-center align-items-center">
        <img src={Question} alt="" style={{ width: "30px", height: "30px" }} />
        <button className={styles.btn} onClick={toggle}>
          <span style={{ textAlign: "left" }}>{t("faq.q_one")}</span>
          {!isOpen ? (
            <div className={styles.icon_container}>
              <AiOutlinePlusCircle className={styles.icon} />
            </div>
          ) : (
            <div className={styles.icon_container}>
              <AiOutlineMinusCircle className={styles.icon} />
            </div>
          )}
        </button>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="d-flex mt-3">
          <img src={Answer} alt="" className={styles.img} />
          <Card style={{ width: "100%", padding: "20px" }}>
            <CardBody>
              <div>
                <div>
                  <span className="fw-bold">
                    {t("faq.q_one_ans_one_title")}
                  </span>
                  <p className="ms-3">{t("faq.q_one_ans_one_detail")}</p>
                </div>
                <div>
                  <span className="fw-bold">2. Machinery</span>
                  <p className="ms-3">
                    (excluding ulta- wide and ultra high machinery)
                  </p>
                </div>
                <div>
                  <span className="fw-bold">3. Food</span>
                  <p className="ms-3">(excluding fresh products)</p>
                </div>
                <div>
                  <span className="fw-bold">
                    4. Chemicals & Medical Supplies
                  </span>
                  <p className="ms-3">
                    (chemical powders,liquids, non- dangerous )
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </Collapse>
    </div>
  );
};
export default QuesOne;
